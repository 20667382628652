import algoliasearch from 'algoliasearch';
import { minLength } from 'vuelidate/lib/validators';

import { Role } from '@frk/graphql-types';

import { hasRoles } from '../../../api/models/Role.core';
import { COLOR as keySkillColor } from '../../../api/models/KeySkill.ui';
import { COLOR as skillColor } from '../../../api/models/Skill.ui';

import FrkFormInputTags from '../../components/FrkFormInputTags.vue';

const { VUE_APP_ALGOLIA_APP_ID, VUE_APP_ALGOLIA_API_SKILLS_KEY, VUE_APP_CONFIG_ENV } = process.env;

// ? Use staging index even in development mode
const indexSuffix = VUE_APP_CONFIG_ENV === 'production' ? '' : '-staging';

const client = algoliasearch(VUE_APP_ALGOLIA_APP_ID, VUE_APP_ALGOLIA_API_SKILLS_KEY);
const index = client.initIndex(`freelances_skills${indexSuffix}`);

export const props = {
  debounceDelayMs: 250,
  placeholder: 'Ajoutez des compétences',
  'suggestions-search': async function (search) {
    const response = await index.searchForFacetValues('skills', search, {
      // ? "React" does not show "Redaction" or "refactoring"
      typoTolerance: false,
    });

    return response.facetHits.map(hit => hit.value);
  },
};

export const skills = {
  name: 'skills',
  label: 'Compétences',
  validations: ({ loggedUser }) => ({
    minLength: hasRoles(loggedUser, [Role.Admin, Role.Commercial, Role.TalentAdvocate, Role.Tech])
      ? minLength(1)
      : minLength(3),
  }),
  initialValue({ apiData }) {
    return apiData.skills || [];
  },
  component: FrkFormInputTags,
  props: {
    ...props,
    color: skillColor,
    isSkills: true,
    suggestionsOnly: true,
  },
};

export const keySkills = {
  name: 'keySkills',
  label: 'Compétences clés',
  component: FrkFormInputTags,
  initialValue({ apiData }) {
    return apiData.keySkills || [];
  },
  props: {
    ...props,
    color: keySkillColor,
    isSkills: true,
  },
};
