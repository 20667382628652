<template>
  <FrkFlexContainer :gap="2">
    <VPopover
      v-if="help"
      placement="bottom"
      trigger="hover"
    >
      <GenericButton
        class="info"
        :color="help.color"
        icon
        outline
        no-shadow
      >
        <i
          class="fa-solid"
          :class="help.iconClass"
        ></i>
      </GenericButton>
      <template #popover>
        <span>{{ help.text }}</span>
      </template>
    </VPopover>
    <select
      class="tw-frk-input"
      :data-test="dataTest || name"
      :disabled="disabled"
      :name="name"
      @input="updateValue"
    >
      <option
        :data-test="!optionsValues.includes(value) ? 'placeholder-selected' : undefined"
        :disabled="required"
        :selected="!optionsValues.includes(value)"
        value=""
      >
        {{ placeholder }}
      </option>
      <option
        :data-test="value === option.value ? 'selected' : undefined"
        :key="option.value"
        :selected="value === option.value"
        :value="option.value"
        v-for="option in options"
      >
        {{ option.label }}
      </option>
    </select>
  </FrkFlexContainer>
</template>

<script>
import { OPTIONS_PLACEHOLDER } from '@frk/helpers';
import FrkFlexContainer from './FrkFlexContainer.vue';
import GenericButton from './GenericButton.vue';

export default {
  name: 'FrkSelect',
  components: {
    FrkFlexContainer,
    GenericButton,
  },
  props: {
    name: {
      type: String,
      default: 'select',
    },
    value: {
      required: false,
    },
    options: {
      type: Array,
      required: true,
    },
    placeholder: {
      type: String,
      default: OPTIONS_PLACEHOLDER,
    },
    required: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    whiteMode: {
      type: Boolean,
      default: false,
    },
    dataTest: {
      type: String,
      optional: true,
    },
    help: {
      type: Object,
      optional: false,
    },
  },
  computed: {
    optionsValues() {
      return this.options.map(o => o.value);
    },
  },
  methods: {
    updateValue(event) {
      const newValue = event.target.value !== '' ? event.target.value : null;

      this.$emit('input', newValue);
    },
  },
};
</script>

<style scoped>
select {
  background: url('../assets/images/chevron-down.svg') no-repeat center, #fff;
  background-size: 10px;
  background-position: calc(100% - 10px);
  padding-right: 30px;
}
</style>
