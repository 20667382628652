//TODO: Composition API + TypeScript + suggestionSearch(emit 😱FRKFormGenerator)

<template>
  <VueTagsInput
    :add-only-from-autocomplete="suggestionsOnly"
    :autocomplete="new Date().getTime()"
    :autocomplete-items="suggestedTags"
    :autocomplete-min-length="0"
    :class="['frk-tag-input-tags', { 'has-input-error': hasInputError }]"
    :data-test="$attrs['data-test'] || $attrs['name']"
    :max-tags="tagsMaxLength"
    :placeholder="tags.length === 0 ? placeholder : ''"
    :tags="tags"
    @before-adding-tag="beforeAddingTag"
    @tags-changed="onTagsChanged"
    v-bind="$attrs"
    v-model="query"
    :delete-on-backspace="false"
    :add-from-paste="false"
  >
    <template #tag-center="{ tag, performDelete, index }">
      <FrkTag
        :color="color"
        :label="tag.text"
        removable
        @remove="performDelete(index)"
      />
    </template>
    <template #autocomplete-item="{ item, performAdd }">
      <h2
        class="text-xl"
        v-if="item.title"
      >
        {{ item.text }}
      </h2>
      <div
        v-else
        @click="performAdd(item)"
      >
        {{ item.text }}
      </div>
    </template>
  </VueTagsInput>
</template>

<script>
import debounce from 'lodash.debounce';
import { pluralize } from '@frk/helpers';

import { VueTagsInput, createTags } from '@frk/vue-tags-input';

import {
  SKILL_ALLOWED_CHARACTERS_REGEX,
  SKILL_MAX_LENGTH,
  SKILL_MIN_LENGTH,
  justNumber,
} from '../../api/models/KeySkill.core';
import FrkTag from './FrkTag.vue';

const warningSymbol = '⚠️';

export default {
  name: 'FrkFormInputTags',
  components: {
    VueTagsInput,
    FrkTag,
  },
  inheritAttrs: false,
  props: {
    // An (empty) array of tag-strings
    value: {
      type: Array,
      required: true,
    },
    // A callback function which performs the search and returns a list of suggestions
    suggestionsSearch: {
      type: Function,
      required: true,
    },
    // Allow the user to add a custom value (not from the suggestion list)
    suggestionsOnly: {
      type: Boolean,
      required: false,
      default: false,
    },
    // A help text displayed only if tagsMaxLength has not been reached
    placeholder: {
      type: String,
      required: false,
      default: '',
    },
    // Inclusive max number of allowed tags [1..2^53 -1]
    tagsMaxLength: {
      type: Number,
      required: false,
      default: Number.MAX_SAFE_INTEGER,
    },
    debounceDelayMs: {
      type: Number,
      required: false,
      default: 0,
    },
    color: {
      type: String,
      required: false,
    },
    isSkills: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      query: '',
      suggestedTags: [],
      hasInputError: false,
    };
  },
  computed: {
    tags() {
      // Ignore null or undefined values
      const cleanedValues = Array.isArray(this.value)
        ? this.value.filter(v => v !== undefined && v !== null).map(v => v.trim())
        : [];

      return createTags(cleanedValues);
    },
  },
  watch: {
    query: 'refreshSuggestions',
  },
  mounted() {
    this.processSuggestionsSearch();
  },
  created() {
    // Trick from https://fr.vuejs.org/v2/guide/computed.html#Observateurs
    this.debounceProcessSuggestionsSearch = debounce(this.processSuggestionsSearch, this.debounceDelayMs);
  },
  methods: {
    async refreshSuggestions() {
      this.hasInputError = false;

      if (this.tagsMaxLength > 0 && this.tags.length >= this.tagsMaxLength) {
        this.query = '';

        return this.setErrorMessage(`Pas plus de ${this.tagsMaxLength} ${pluralize('entrée', this.tagsMaxLength)}`);
      }

      if (this.isSkills) {
        if (justNumber(this.query)) {
          return this.setErrorMessage("Il n'est pas autorisé de fournir une compétence juste avec des nombres");
        }

        if (this.query.length === SKILL_MIN_LENGTH) {
          return this.setErrorMessage('2 caractères minimum pour une compétence');
        }
        if (this.query.length > SKILL_MAX_LENGTH) {
          return this.setErrorMessage('30 caractères maximum pour une compétence');
        }
        if (SKILL_ALLOWED_CHARACTERS_REGEX.test(this.query)) {
          return this.setErrorMessage(
            'Les caractères spéciaux présents dans cette compétence sont interdits. Si vous utilisez des séparateurs, il est probable que vous souhaitiez indiquer plusieurs compétences différentes.'
          );
        }
      }

      return this.debounceProcessSuggestionsSearch();
    },
    async processSuggestionsSearch() {
      const suggestions = await this.suggestionsSearch(this.query);

      if (suggestions.length === 0 && this.suggestionsOnly) {
        // No suggestion matches the query && only suggestion items are allowed
        this.setErrorMessage('Valeur non autorisée');
        return;
      }

      if (this.isSkills) {
        this.suggestedTags = createTags(suggestions).filter(
          tag => tag.text.length <= SKILL_MAX_LENGTH && !SKILL_ALLOWED_CHARACTERS_REGEX.test(tag.text)
        );
        return;
      }

      this.suggestedTags = createTags(suggestions);
    },
    setErrorMessage(msg) {
      this.hasInputError = true;

      this.suggestedTags = createTags([`${warningSymbol} ${msg}`]);
    },
    beforeAddingTag({ tag: newTag, addTag }) {
      const trimmedNewTag = newTag.text.trim();

      // Cancel if a warning suggestion have been selected
      if (this.hasInputError) {
        return;
      }

      // Cancel if after trim, the tags is already in the list !
      if (this.tags.some(tag => tag.text === trimmedNewTag)) {
        return;
      }

      addTag(trimmedNewTag);
    },
    onTagsChanged(updatedTags) {
      // Convert the tags in string[]
      const tagsValues = updatedTags.map(tag => tag.text);

      this.$emit('input', tagsValues);
    },
  },
};
</script>

<style lang="scss">
.vue-tags-input.frk-tag-input-tags {
  background-color: transparent !important;
  max-width: none !important;
  .ti-input {
    border: none;
    border-radius: 0px;
    padding: 0;
    input {
      border: inherit;
      @apply tw-frk-input;
    }
    .ti-deletion-mark {
      border-radius: $border-radius-medium;
      background-color: $color-red;
    }
  }
  .ti-autocomplete {
    border: solid 1px #e5e7eb;
    border-radius: 0.5rem;
    position: relative;
    padding: 10px 20px;
    margin-top: 10px;
    color: $color-grey-2;
    max-height: 360px;
    overflow-y: scroll;
    .ti-item.ti-selected-item {
      background-color: $color-grey-7;
      border-radius: $border-radius-medium;
    }
  }
}

.vue-tags-input.frk-tag-input-tags .ti-tag {
  background-color: transparent;
  border: none;
  border-radius: 0;
  box-shadow: none;
  padding: 0;
  margin: 4px;
}

.vue-tags-input.frk-tag-input-tags .ti-new-tag-input-wrapper {
  padding: 0;
  margin: 0;
}

.ti-new-tag-input-wrapper .ti-new-tag-input {
  border: solid 1px red;
}

.vue-tags-input.frk-tag-input-tags .ti-actions {
  display: none;
}

.vue-tags-input.frk-tag-input-tags .ti-icon-close {
  display: none;
}

.ti-item:has(h2) {
  @apply tw-py-1 tw-text-lg hover:tw-bg-inherit;
  &.ti-selected-item {
    background: transparent !important;
    color: $color-grey-2 !important;
    cursor: unset;
  }

  h2 {
    @apply tw-font-bold;
  }
}
</style>
