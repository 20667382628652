import { CompanyForm } from '@frk/graphql-types';
import { ConstantOption } from './utils.core';

export const COMPANY_FORM_OPTIONS: ConstantOption<CompanyForm>[] = [
  { value: CompanyForm.Ei, label: 'Entreprise Individuelle' },
  { value: CompanyForm.Eirl, label: 'Entreprise Individuelle à Responsabilité' },
  { value: CompanyForm.Eurl, label: 'Entreprise Unipersonnelle à Responsabilité Limitée' },
  { value: CompanyForm.Sa, label: 'Société Anonyme' },
  { value: CompanyForm.Sarl, label: 'Société à Responsabilité Limitée' },
  { value: CompanyForm.Sas, label: 'Société par Actions Simplifiée' },
  { value: CompanyForm.Sasu, label: 'Société par Actions Simplifiée Unipersonnelle' },
  { value: CompanyForm.EiAeMe, label: 'EI / AE / ME (Obsolete)' },
  { value: CompanyForm.SarlEurl, label: 'EURL / SARL (Obsolete)' },
  { value: CompanyForm.SasSasu, label: 'SAS / SASU (Obsolete)' },
];
