import { isDefined } from '@frk/helpers';

// TODO Migrate to TS and forget about named paramaters in an object literal (simplification...)
export function withFieldNames({ fieldDefs, inputRoot }) {
  return Object.entries(fieldDefs).reduce(
    (newFieldDefs, [name, fieldDef]) => ({
      ...newFieldDefs,
      [name]: {
        ...fieldDef,
        name: inputRoot ? `${inputRoot}.${name}` : name,
      },
    }),
    {}
  );
}

export async function mutateContextIf({ condition, formContext, mutations }) {
  if (condition) {
    for (const [fieldName, fieldValue] of Object.entries(mutations)) {
      // only mutate the form context if the field is present in the form!
      if (isDefined(formContext[fieldName])) {
        const mutatedValue = typeof fieldValue === 'function' ? await fieldValue({ formContext }) : fieldValue;

        formContext[fieldName] = mutatedValue;
      }
    }
  }
}
