<template>
  <img
    :class="{ 'img-rounded': rounded }"
    :src="imageSrc"
    :alt="alt"
    :style="`min-width: ${width}; height: ${height}; width: ${width}; min-height: ${height}`"
    v-show="imageSrc"
  />
</template>

<script>
export default {
  props: {
    src: {
      type: String,
    },
    alt: {
      type: String,
      optional: true,
    },
    srcBlank: {
      type: String,
    },
    width: {
      type: String,
      default: '100%',
    },
    height: {
      type: String,
      default: 'auto',
    },
    rounded: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    imageSrc() {
      return this.src || this.srcBlank;
    },
  },
};
</script>

<style lang="scss" scoped>
img {
  object-fit: cover;
  &.img-rounded {
    border-radius: 100%;
  }
}
</style>
