import { ClientInitialPeriodStakeholdersEnum } from '@frk/graphql-types';
import { required } from 'vuelidate/lib/validators';

import {
  INITIAL_PERIOD_STAKEHOLDERS_OPTIONS,
  INITIAL_PERIOD_UNIT_OPTIONS,
  PAYMENT_PERIOD_OPTIONS,
} from '../../../api/models/Client.core';

import { withFieldNames } from '.';
import ClientNoticePeriod from '../../components/ClientNoticePeriod.vue';
import FrkInput from '../../components/FrkInput.vue';
import FrkSelect from '../../components/FrkSelect.vue';
import { fields as businessTagsFields } from './businessTagsFields';

// fields specific to a Client user though defined at the root of the User model...
const fieldDefs = {
  noticePeriod: {
    label: 'Délai de préavis de rupture',
    component: ClientNoticePeriod,
  },
  paymentPeriod: {
    label: 'Délai de paiement',
    props: {
      options: PAYMENT_PERIOD_OPTIONS,
    },
    component: FrkSelect,
  },
};

const hasInitialPeriod = formContext =>
  !!formContext.client?.initialPeriod?.stakeholders &&
  formContext.client?.initialPeriod?.stakeholders !== ClientInitialPeriodStakeholdersEnum.None;

const initialPeriodFieldDefs = {
  stakeholders: {
    label: 'Periode initiale',
    component: FrkSelect,
    props: {
      options: INITIAL_PERIOD_STAKEHOLDERS_OPTIONS,
      required: true,
    },
    validations: {
      required,
    },
  },
  value: {
    component: FrkInput,
    props: {
      min: 1,
      type: 'number',
    },
    showIf({ formContext }) {
      return hasInitialPeriod(formContext);
    },
  },
  unit: {
    component: FrkSelect,
    props: {
      options: INITIAL_PERIOD_UNIT_OPTIONS.map(o => ({ ...o, label: o.label({ value: 2 }) })),
      required: true,
    },
    showIf({ formContext }) {
      return hasInitialPeriod(formContext);
    },
  },
};

export const fields = {
  businessTags: businessTagsFields.client,
  ...withFieldNames({ fieldDefs, inputRoot: 'client' }),
  initialPeriod: {
    ...withFieldNames({ fieldDefs: initialPeriodFieldDefs, inputRoot: 'client.initialPeriod' }),
  },
};
