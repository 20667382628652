/* eslint-disable */

import * as SchemaTypes from '@frk/graphql-types';

import { gql } from '@frk/graphql-vue-apollo-patch';
import { createMutationFunction, createSmartQueryOptionsFunction, createSmartSubscriptionOptionsFunction } from 'vue-apollo-smart-ops';
import { ApolloError } from '@apollo/client';
export type ProspectClientAddMutationVariables = SchemaTypes.Exact<{
  infos: SchemaTypes.ProspectClientAddInput;
}>;


export type ProspectClientAddMutation = { __typename?: 'Mutation', prospectClientAdd?: string | null };

export type ProspectFreelanceAddMutationVariables = SchemaTypes.Exact<{
  infos: SchemaTypes.ProspectFreelanceAddInput;
}>;


export type ProspectFreelanceAddMutation = { __typename?: 'Mutation', prospectFreelanceAdd?: string | null };


export const ProspectClientAddDocument = gql`
    mutation prospectClientAdd($infos: ProspectClientAddInput!) {
  prospectClientAdd(infos: $infos)
}
    `;

/**
 * __prospectClientAddMutation__
 *
 * To run a mutation, you call `prospectClientAddMutation` within a Vue component and pass it
 * your Vue app instance along with any options that fit your needs.
 *
 * @param app, a reference to your Vue app instance (which must have a `$apollo` property)
 * @param options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/core/ApolloClient/#ApolloClient.mutate
 * @param client (optional), which can be an instance of `DollarApollo` or the `mutate()` function provided by an `<ApolloMutation>` component
 *
 * @example
 * const { success, data, errors } = prospectClientAddMutation(this, {
 *   variables: {
 *     infos: // value for 'infos'
 *   },
 * });
 */
export const prospectClientAddMutation = createMutationFunction<
  ProspectClientAddMutation,
  ProspectClientAddMutationVariables,
  ApolloError
>(ProspectClientAddDocument);

export const ProspectFreelanceAddDocument = gql`
    mutation prospectFreelanceAdd($infos: ProspectFreelanceAddInput!) {
  prospectFreelanceAdd(infos: $infos)
}
    `;

/**
 * __prospectFreelanceAddMutation__
 *
 * To run a mutation, you call `prospectFreelanceAddMutation` within a Vue component and pass it
 * your Vue app instance along with any options that fit your needs.
 *
 * @param app, a reference to your Vue app instance (which must have a `$apollo` property)
 * @param options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/core/ApolloClient/#ApolloClient.mutate
 * @param client (optional), which can be an instance of `DollarApollo` or the `mutate()` function provided by an `<ApolloMutation>` component
 *
 * @example
 * const { success, data, errors } = prospectFreelanceAddMutation(this, {
 *   variables: {
 *     infos: // value for 'infos'
 *   },
 * });
 */
export const prospectFreelanceAddMutation = createMutationFunction<
  ProspectFreelanceAddMutation,
  ProspectFreelanceAddMutationVariables,
  ApolloError
>(ProspectFreelanceAddDocument);
