
import Vue from 'vue';
import dateUtils from '../../api/date';

export type LabelComponentRequiredProps = {
  text: string;
};

// Note about useless complexity and confusion :
// This is very confusing, validator or validators ?
// The TS Migration of this component is partial as out of scope considering the roadmap
// -> original intent : fix a label display bug with labelComponent on FrkFormGenerator
// See

export default Vue.extend({
  name: 'FrkFormValidationErrors',
  props: {
    validator: {
      type: Object,
      required: true,
    },
    messages: {
      type: Object,
      required: false,
    },
  },
  computed: {
    /**
     * @type {<fieldLabel: string, fieldName: string, fieldValue: any, message: string, params: Object>[]}
     */
    errorMessages(): string[] {
      return this.flattenValidatorObjects(this.validator)
        .filter(v => v.$error && v.hasError)
        .map(v => ({ ...v, message: this.getFormattedMessage(v) }));
    },
    validationKeyMessages(): {
      [validatorName: string]: (validatorArgs: {
        fieldLabel: string;
        fieldValue: any;
        params: any;
        validator: any;
      }) => string;
    } {
      return {
        dateAfter: ({ fieldLabel, params }) =>
          `'${fieldLabel}' doit être une date postérieure à '${dateUtils.formatDate(params.date, 'DD/MM/YYYY')}`,
        dateAfterField: ({ fieldLabel, params, validator }) =>
          `'${fieldLabel}' doit être une date postérieure à celle du champ '${this.getFieldLabel(
            validator,
            params.fieldName
          )}' : ${dateUtils.formatDate(this.getFieldValue(validator, params.fieldName), 'DD/MM/YYYY')}`,
        dateBefore: ({ fieldLabel, params }) =>
          `'${fieldLabel}' doit être une date antérieure à '${dateUtils.formatDate(params.date, 'DD/MM/YYYY')}`,
        email: ({ fieldLabel }) => `'${fieldLabel}' doit être un email`,
        minLength: ({ fieldLabel, fieldValue, params: { min } }) =>
          Array.isArray(fieldValue)
            ? `'${fieldLabel}' doit contenir au moins ${min} entrées`
            : `'${fieldLabel}' doit contenir au moins ${min} caractères`,
        maxLength: ({ fieldLabel, fieldValue, params: { max } }) =>
          Array.isArray(fieldValue)
            ? `'${fieldLabel}' ne doit pas excéder ${max} entrées`
            : `'${fieldLabel}' ne doit pas excéder ${max} caractères`,
        maxSize: ({ fieldLabel, params: { max } }) => `'${fieldLabel}' ne doit pas excéder ${max} caractères`, // 'caractères' means 'bytes' → more UX friendly
        minValue: ({ fieldLabel, params: { min } }) => `'${fieldLabel}' ne doit pas être inférieur à ${min}`,
        maxValue: ({ fieldLabel, params: { max } }) => `'${fieldLabel}' ne doit pas excéder ${max}`,
        required: ({ fieldLabel }) => `'${fieldLabel}' est requis`,
        ...this.messages,
      };
    },
  },
  methods: {
    getFieldLabel(validator: any, fieldName?: string) {
      const source = fieldName ? validator[fieldName] : validator;

      // $params.label could be null when validators and vuelidate plugin are not imported from the same package
      // See https://github.com/freelanceRepublik/westeros/issues/284
      let fieldLabel = source?.$params.label?.type || fieldName;

      // Label displayed by FrkFormGenerator with `labelComponent` defines a short text label on this key
      if (fieldLabel?.text) {
        fieldLabel = fieldLabel.text;
      }

      return fieldLabel;
    },
    getFieldValue(validator: any, fieldName?: string) {
      const source = fieldName ? validator[fieldName] : validator;

      return source.$model;
    },
    /**
     * Flattens a deep Vuelidate Validator object to a normalized flat structure
     * @param {object} validator - Vuelidate Validator object
     * @param {string} [fieldName] - Name of validated field. Builds a dot.path when used on deep objects. Passed by recursive call to same function.
     * @param {object} rootValidator - Vuelidate Validator root object
     * @return {VeeFlatMultiErrorBag}
     */
    flattenValidatorObjects(
      validator: any,
      fieldName: string | undefined = undefined,
      rootValidator: any = undefined
    ): any[] {
      // Loop the validator objects
      return (
        Object.entries(validator)
          // Leave those that don't have $ in their name with exception of $each
          .filter(([key]) => !key.startsWith('$') || key === '$each')
          .reduce((errors, [key, value]) => {
            // if it's an object, it's probably a deeply nested object
            if (typeof value === 'object') {
              // if fieldName is available, build it like `model.brand` from `model.$each.0.brand`.
              const stringValidatorName = fieldName ? `${fieldName}.${key}` : key; // fallback to the "key" if "fieldName" is not available

              // Key can be "$each", a "string" or a "number" from inside "$each".
              // If "key" is "$each" or a string (from a nested object like "address.postal_code"),
              // use the passed fieldName as its a recursive call from previous call.
              const nestedValidatorName =
                key === '$each' || !Number.isNaN(Number.parseInt(key, 10)) ? fieldName : stringValidatorName;
              // recursively call the flatten again on the same error object, looking deep into it.
              return errors.concat(
                this.flattenValidatorObjects(value, nestedValidatorName, rootValidator || validator)
              );
            } // else it's the validated prop

            const params = { ...validator.$params[key] };

            const fieldLabel = this.getFieldLabel(validator);
            const fieldValue = this.getFieldValue(validator);

            // Delete type as it is coming from Vuelidate and may interfere with user custom attributes
            delete params.type;

            errors.push({
              fieldName,
              fieldLabel,
              fieldValue,
              validationKey: key,
              hasError: !value,
              params,
              $dirty: validator.$dirty,
              $error: validator.$error,
              $invalid: validator.$invalid,
              validator: rootValidator || validator,
            });

            return errors;
          }, [] as any)
      );
    },
    getFormattedMessage(error: any): string {
      const { fieldName, validationKey } = error;
      const { validationKeyMessages } = this;

      if (validationKeyMessages[validationKey]) {
        return validationKeyMessages[validationKey](error);
      }

      return `'${fieldName}' est erronné [${validationKey}]`;
    },
  },
});
