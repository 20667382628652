<template>
  <div
    v-if="active"
    class="tw-relative tw-flex tw-max-h-screen tw-min-h-screen tw-w-full tw-flex-col tw-items-center tw-justify-center tw-overflow-hidden"
    :class="[background]"
  >
    <div
      class="tw-h-full tw-rotate-90"
      ref="coverAnim"
    ></div>
    <img
      v-if="mode === 'cover' || mode === 'route'"
      class="tw-absolute tw-h-[3rem]"
      src="../assets/images/logo/logo_frk.svg"
    />
    <h3
      v-else
      class="tw-text-md tw-absolute tw-font-semibold tw-text-gray-800 dark:tw-text-white"
    >
      En cours de chargement...
    </h3>
  </div>
</template>

<script>
import lottie from 'lottie-web';
import * as freelanceCoverAnim from '../assets/anim/load.json';

export default {
  props: {
    active: {
      type: Boolean,
      required: true,
    },
    mode: {
      type: String,
      default: 'route',
      validator: value => ['route', 'block', 'cover'].includes(value),
    },
    background: {
      type: String,
      default: 'tw-bg-gray-50 dark:tw-bg-slate-900',
    },
  },
  mounted() {
    this.$nextTick(() => {
      if (!this.$refs.coverAnim) return;
      const anim = lottie.loadAnimation({
        container: this.$refs.coverAnim,
        renderer: 'svg',
        loop: true,
        autoplay: true,
        animationData: freelanceCoverAnim.default,
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid slice',
          hideOnTransparent: true,
        },
      });
    });
  },
};
</script>
