import { COLOR } from '@frk/commons/api/models/BusinessTag.ui';

import FrkFormInputTags from '../../components/FrkFormInputTags.vue';

export const fields = ['freelance', 'mission', 'client'].reduce(
  (fieldsTable, entity) => ({
    ...fieldsTable,
    [entity]: {
      name: 'businessTags',
      label: 'Business Tags',
      component: FrkFormInputTags,
      initialValue({ apiData }) {
        return apiData.businessTags || [];
      },
      props: {
        debounceDelayMs: 250,
        placeholder: 'Ajoutez un ou plusieurs business tags',
        color: COLOR,
        'suggestions-search': async function (query) {
          const searchFn = await this.$algolia.setupSearchBusinessTagsByIndex(`${entity}s`);
          return searchFn(query);
        },
      },
    },
  }),
  {}
);
