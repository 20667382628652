import { maxValue, minValue, numeric, required } from 'vuelidate/lib/validators';

import { Role } from '@frk/graphql-types';

import {
  ACTIVITY_OPTIONS,
  EXPECTED_CONTRACT_OPTIONS,
  SOURCE_OPTIONS,
  TIMING_OPTIONS,
} from '../../../api/models/Freelance.core';
import { LOCATION_OPTIONS } from '../../../api/models/Location.core';
import { hasRoles } from '../../../api/models/Role.core';

import AddressBigCitiesTagsInput from '../../components/AddressBigCitiesTagsInput.vue';
import CandidateJobsTagsInput from '../../components/CandidateJobsTagsInput.vue';
import FrkInput from '../../components/FrkInput.vue';
import FrkPlacesInput from '../../components/FrkPlacesInput.vue';
import FrkSelect from '../../components/FrkSelect.vue';
import FrkTextArea from '../../components/FrkTextArea.vue';

import { withFieldNames } from '.';
import { props, skills } from './skillsFields';

const FREELANCE_PRICE_MIN = 50;
const FREELANCE_PRICE_MAX = 2000;

const salary = {
  validations: ({ loggedUser }) => ({
    required: hasRoles(loggedUser, [Role.Admin, Role.Commercial, Role.TalentAdvocate, Role.Tech]) ? false : required,
    numeric,
  }),
  component: FrkInput,
  props: {
    type: 'number',
    step: 1,
    placeholder: '€/An',
    nullIfEmpty: true,
  },
};

const price = {
  ...salary,
  validations: ({ loggedUser }) => ({
    required: hasRoles(loggedUser, [Role.Admin, Role.Commercial, Role.TalentAdvocate, Role.Tech]) ? false : required,
    numeric,
    minValue: minValue(FREELANCE_PRICE_MIN),
    maxValue: maxValue(FREELANCE_PRICE_MAX),
  }),
  component: FrkInput,
  props: {
    type: 'number',
    min: FREELANCE_PRICE_MIN,
    max: FREELANCE_PRICE_MAX,
    step: 5,
    placeholder: '€/Jour',
    nullIfEmpty: true,
  },
};

export const fieldDefs = {
  activity: {
    label: 'Contrat actuel',
    component: FrkSelect,
    props: {
      options: ACTIVITY_OPTIONS,
    },
  },
  expectedContract: {
    label: 'Contrat recherché',
    component: FrkSelect,
    props: {
      options: EXPECTED_CONTRACT_OPTIONS,
    },
    validations: {
      required,
    },
  },
  'geographicAvailability.address': {
    label: 'Adresse',
    component: FrkPlacesInput,
  },
  'geographicAvailability.mobility': {
    label: 'Prêt à se déplacer 2 fois par semaine à',
    helpText: 'Liste de villes parmi les 20 plus grandes villes de France',
    component: AddressBigCitiesTagsInput,
  },
  'geographicAvailability.houseMove': {
    label: 'Prêt à se relocaliser à',
    helpText: 'Liste de villes parmi les 20 plus grandes villes de France',
    component: AddressBigCitiesTagsInput,
  },
  description: {
    label: 'Description',
    component: FrkTextArea,
    props: {
      rows: 10,
    },
  },
  'qualification.frkOpinion': {
    label: 'Opinion FRK',
    component: FrkTextArea,
    props: {
      rows: 10,
    },
  },
  location: {
    label: 'Localisation',
    component: FrkSelect,
    props: {
      options: LOCATION_OPTIONS,
    },
    validations: {
      required,
    },
  },
  'price.target': {
    ...price,
    label: 'TJM Cible',
  },
  'price.minimum': {
    ...price,
    label: 'TJM Minimum',
  },
  'price.last': {
    ...price,
    label: 'Dernier TJM',
  },
  'salary.target': {
    ...salary,
    label: 'Salaire Cible',
  },
  'salary.minimum': {
    ...salary,
    label: 'Salaire Minimum',
  },
  'salary.last': {
    ...salary,
    label: 'Dernier salaire',
  },
  skills,
  timing: {
    label: 'Mission recherchée',
    component: FrkSelect,
    props: {
      options: TIMING_OPTIONS,
    },
  },
  jobs: {
    label: 'Postes recherchés',
    initialValue({ apiData }) {
      return (apiData && apiData.freelance?.jobs) || [];
    },
    validations: {
      required,
    },
    component: CandidateJobsTagsInput,
    props: {
      name: 'CandidateJobsTagsInput',
    },
  },
  source: {
    label: 'Source',
    validations: {
      required,
    },
    component: FrkSelect,
    props: {
      placeholder: 'Sélectionnez une source',
      options: SOURCE_OPTIONS,
    },
  },
};

export const fields = withFieldNames({ fieldDefs, inputRoot: 'freelance' });
