
import Vue from 'vue';
import { formatAmountFromCents } from '@frk/helpers';

import FrkInput from './FrkInput.vue';

export default Vue.extend({
  name: 'GenericAmountInput',
  components: { FrkInput },
  props: {
    placeholder: {
      type: String,
    },
    value: {
      type: Number,
    },
    min: {
      type: Number,
      default: 0,
    },
    max: {
      type: Number,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    step: {
      type: Number,
      required: false,
      default: 0.01,
    },
    size: {
      type: String,
      default: 'small',
    },
    name: {
      type: String,
    },
  },
  computed: {
    euroAmount(): string {
      return formatAmountFromCents(this.value, { trimTrailingZero: true });
    },
  },
  methods: {
    emitAmountInCents(value: string) {
      this.$emit(
        'input',
        // Convert regular euro amount in cents, and prevent negative values to be stored
        value ? Math.floor(Math.abs(Number(value)) * 100) : null
      );
    },
  },
});
