var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('span',{class:[
    'tw-leading-sm',
    'tw-inline-flex',
    'tw-place-items-center',
    'tw-rounded-full',
    'tw-px-3',
    'tw-py-1',
    'tw-text-center',
    'tw-text-sm',
    'tw-font-semibold',
    _setup.colorClasses,
    _setup.clickableClasses,
  ],on:{"click":function($event){return _vm.$emit('click')}}},[_vm._v(" "+_vm._s(_setup.props.label)+" "),(_vm.removable)?_c('i',{staticClass:"fas fa-times remove tw-ml-2 tw-cursor-pointer",on:{"click":function($event){return _vm.$emit('remove')}}}):_vm._e(),_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }