"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getValueByLabel = exports.getLabelByValue = exports.getByValue = void 0;
function getByValue(options, value) {
    const option = options.find(o => o.value === value);
    const defaultOption = options.find(o => o.default);
    if (!option && !defaultOption) {
        console.log('Valeur "null" ou "undefined" en DB/Algolia/CIO en lien avec la ConstantOption:', options);
        throw new Error('Ayooo ingénieur ! Mauvaise valeur de ConstantOption !');
    }
    return option || defaultOption;
}
exports.getByValue = getByValue;
/**
 * Utility function not suitable for options with a functional label : (any) => string
 */
function getLabelByValue(options, value) {
    const option = getByValue(options, value);
    if (typeof option.label === 'function') {
        throw new Error('This function cannot be used for complex label computation ! Do it manually.');
    }
    return option.label;
}
exports.getLabelByValue = getLabelByValue;
function getValueByLabel(options, label) {
    const option = options.find(o => o.label === label);
    return option.value;
}
exports.getValueByLabel = getValueByLabel;
