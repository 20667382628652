"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
exports.default = (0, vue_1.defineComponent)({
    __name: 'FrkAlert',
    props: {
        emoji: null,
        text: null,
        title: null
    },
    setup(__props) {
        return { __sfc: true, };
    }
});
