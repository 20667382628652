"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BIG_CITIES_ADDRESS_LIST = exports.getRegionFromGeocoding = exports.formatAddress = exports.DEFAULT_FACET_DISTANCE = void 0;
exports.DEFAULT_FACET_DISTANCE = {
    address: null,
    radius: 75,
};
function formatAddress(address) {
    const noValue = "Pas d'adresse";
    if (!address || Object.keys(address).length === 0) {
        return noValue;
    }
    const { city, region, country } = address;
    if (city) {
        return `${city}, ${region}`;
    }
    if (country) {
        return country;
    }
    return noValue;
}
exports.formatAddress = formatAddress;
function getRegionFromGeocoding(place) {
    let region;
    for (const type of ['administrative_area_level_1', 'administrative_area_level_2', 'country']) {
        region = place.address_components?.find(component => component.types.includes(type))?.long_name;
        if (region) {
            return region;
        }
    }
    // ? Typescript hack: we know for sure that country will return a string
    // ? This line should never be reached at runtime 🤷‍♂️
    return region;
}
exports.getRegionFromGeocoding = getRegionFromGeocoding;
exports.BIG_CITIES_ADDRESS_LIST = [
    {
        region: 'Île-de-France',
        city: 'Paris',
        country: 'France',
        latlng: { lat: 48.856614, lng: 2.3522219 },
        placeId: 'ChIJD7fiBh9u5kcRYJSMaMOCCwQ',
        value: 'Paris, France',
    },
    {
        region: "Provence-Alpes-Côte d'Azur",
        city: 'Marseille',
        country: 'France',
        latlng: { lat: 43.296482, lng: 5.36978 },
        placeId: 'ChIJM1PaREO_yRIRIAKX_aUZCAQ',
        value: 'Marseille, France',
    },
    {
        region: 'Auvergne-Rhône-Alpes',
        city: 'Lyon',
        country: 'France',
        latlng: { lat: 45.764043, lng: 4.835659 },
        placeId: 'ChIJl4foalHq9EcR8CG75CqrCAQ',
        value: 'Lyon, France',
    },
    {
        region: 'Occitanie',
        city: 'Toulouse',
        country: 'France',
        latlng: { lat: 43.604652, lng: 1.444209 },
        placeId: 'ChIJ_1J17G-7rhIRMBBBL5z2BgQ',
        value: 'Toulouse, France',
    },
    {
        region: "Provence-Alpes-Côte d'Azur",
        city: 'Nice',
        country: 'France',
        latlng: { lat: 43.7101728, lng: 7.261953200000001 },
        placeId: 'ChIJMS2FahDQzRIRcJqX_aUZCAQ',
        value: 'Nice, France',
    },
    {
        region: 'Pays de la Loire',
        city: 'Nantes',
        country: 'France',
        latlng: { lat: 47.218371, lng: -1.553621 },
        placeId: 'ChIJra6o8IHuBUgRMO0NHlI3DQQ',
        value: 'Nantes, France',
    },
    {
        region: 'Occitanie',
        city: 'Montpellier',
        country: 'France',
        latlng: { lat: 43.610769, lng: 3.876716 },
        placeId: 'ChIJsZ3dJQevthIRAuiUKHRWh60',
        value: 'Montpellier, France',
    },
    {
        region: 'Grand Est',
        city: 'Strasbourg',
        country: 'France',
        latlng: { lat: 48.5734053, lng: 7.752111299999999 },
        placeId: 'ChIJwbIYXknIlkcRHyTnGDFIGpc',
        value: 'Strasbourg, France',
    },
    {
        region: 'Nouvelle-Aquitaine',
        city: 'Bordeaux',
        country: 'France',
        latlng: { lat: 44.837789, lng: -0.57918 },
        placeId: 'ChIJgcpR9-gnVQ0RiXo5ewOGY3k',
        value: 'Bordeaux, France',
    },
    {
        region: 'Hauts-de-France',
        city: 'Lille',
        country: 'France',
        latlng: { lat: 50.62925, lng: 3.057256 },
        placeId: 'ChIJEW4ls3nVwkcRYGNkgT7xCgQ',
        value: 'Lille, France',
    },
    {
        region: 'Brittany',
        city: 'Rennes',
        country: 'France',
        latlng: { lat: 48.117266, lng: -1.6777926 },
        placeId: 'ChIJhZDWpy_eDkgRMKvkNs2lDAQ',
        value: 'Rennes, France',
    },
    {
        region: 'Grand Est',
        city: 'Reims',
        country: 'France',
        latlng: { lat: 49.258329, lng: 4.031696 },
        placeId: 'ChIJCTtTSjN06UcRwEU7mrlfCgQ',
        value: '51100 Reims, France',
    },
    {
        region: 'Normandy',
        city: 'Le Havre',
        country: 'France',
        latlng: { lat: 49.49437, lng: 0.107929 },
        placeId: 'ChIJfYshlSMv4EcREq8zr3qGwVs',
        value: 'Le Havre, France',
    },
    {
        region: 'Auvergne-Rhône-Alpes',
        city: 'Saint-Étienne',
        country: 'France',
        latlng: { lat: 45.439695, lng: 4.3871779 },
        placeId: 'ChIJFeTPDf-r9UcRVldjh9uyMQY',
        value: 'Saint-Étienne, France',
    },
    {
        region: "Provence-Alpes-Côte d'Azur",
        city: 'Toulon',
        country: 'France',
        latlng: { lat: 43.124228, lng: 5.928 },
        placeId: 'ChIJ_bI1ewIbyRIRMMiP_aUZCAQ',
        value: 'Toulon, France',
    },
    {
        region: 'Auvergne-Rhône-Alpes',
        city: 'Grenoble',
        country: 'France',
        latlng: { lat: 45.188529, lng: 5.724524 },
        placeId: 'ChIJb76J1ov0ikcRmFOZbs0QjGE',
        value: 'Grenoble, France',
    },
    {
        region: 'Bourgogne-Franche-Comté',
        city: 'Dijon',
        country: 'France',
        latlng: { lat: 47.322047, lng: 5.04148 },
        placeId: 'ChIJdZb974yd8kcR0FgUszTOCQQ',
        value: '21000 Dijon, France',
    },
    {
        region: 'Pays de la Loire',
        city: 'Angers',
        country: 'France',
        latlng: { lat: 47.47116159999999, lng: -0.5518257 },
        placeId: 'ChIJnY7lANp4CEgRMJwNHlI3DQQ',
        value: 'Angers, France',
    },
    {
        region: 'Auvergne-Rhône-Alpes',
        city: 'Villeurbanne',
        country: 'France',
        latlng: { lat: 45.771944, lng: 4.8901709 },
        placeId: 'ChIJryN2zS_A9EcRUiZbJSsMusM',
        value: '69100 Villeurbanne, France',
    },
    {
        region: 'Pays de la Loire',
        city: 'Le Mans',
        country: 'France',
        latlng: { lat: 48.00611000000001, lng: 0.199556 },
        placeId: 'ChIJMarzFNKI4kcRf-B9akxdAmk',
        value: 'Le Mans, France',
    },
    {
        region: "Provence-Alpes-Côte d'Azur",
        city: 'Aix-en-Provence',
        country: 'France',
        latlng: { lat: 43.529742, lng: 5.447426999999999 },
        placeId: 'ChIJWRK5BKONyRIRo4i2yL5TuVw',
        value: 'Aix-en-Provence, France',
    },
    {
        region: 'Auvergne-Rhône-Alpes',
        city: 'Clermont-Ferrand',
        country: 'France',
        latlng: { lat: 45.77722199999999, lng: 3.087025 },
        placeId: 'ChIJB4Uuf90b90cRIm-gOGAwlDk',
        value: 'Clermont-Ferrand, France',
    },
    {
        region: 'Brittany',
        city: 'Brest',
        country: 'France',
        latlng: { lat: 48.3907119, lng: -4.4869723 },
        placeId: 'ChIJk1uS2eG7FkgRqzCcF1iDSMY',
        value: 'Brest, France',
    },
    {
        region: 'Nouvelle-Aquitaine',
        city: 'Limoges',
        country: 'France',
        latlng: { lat: 45.83361900000001, lng: 1.261105 },
        placeId: 'ChIJYQRo5q80-UcREU02WTFKvhA',
        value: 'Limoges, France',
    },
    {
        region: 'Centre-Val de Loire',
        city: 'Tours',
        country: 'France',
        latlng: { lat: 47.394144, lng: 0.68484 },
        placeId: 'ChIJVZqXSrPV_EcRMIQ4BdfIDQQ',
        value: 'Tours, France',
    },
    {
        region: 'Hauts-de-France',
        city: 'Amiens',
        country: 'France',
        latlng: { lat: 49.894067, lng: 2.295753 },
        placeId: 'ChIJC3aL1xOE50cR4CBigT7xCgQ',
        value: 'Amiens, France',
    },
    {
        region: 'Occitanie',
        city: 'Perpignan',
        country: 'France',
        latlng: { lat: 42.6886591, lng: 2.8948332 },
        placeId: 'ChIJ_Yj9gE5usBIRT93yIGUwgxw',
        value: 'Perpignan, France',
    },
    {
        region: 'Grand Est',
        city: 'Metz',
        country: 'France',
        latlng: { lat: 49.1193089, lng: 6.175715599999999 },
        placeId: 'ChIJqbZ0YBvclEcRaaa7Nbbka1k',
        value: 'Metz, France',
    },
];
