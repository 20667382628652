/* eslint-disable */

import * as SchemaTypes from '@frk/graphql-types';

import { gql } from '@frk/graphql-vue-apollo-patch';
import { createMutationFunction, createSmartQueryOptionsFunction, createSmartSubscriptionOptionsFunction } from 'vue-apollo-smart-ops';
import { ApolloError } from '@apollo/client';
export type UserFindByNameOrEmailQueryVariables = SchemaTypes.Exact<{
  firstname?: SchemaTypes.InputMaybe<SchemaTypes.Scalars['String']['input']>;
  lastname?: SchemaTypes.InputMaybe<SchemaTypes.Scalars['String']['input']>;
  email?: SchemaTypes.InputMaybe<SchemaTypes.Scalars['String']['input']>;
}>;


export type UserFindByNameOrEmailQuery = { __typename?: 'Query', userFindByNameOrEmail?: { __typename?: 'User', _id?: string | null, firstname: string, lastname: string, email?: any | null, roles: Array<SchemaTypes.Role> } | null };


export const UserFindByNameOrEmailDocument = gql`
    query userFindByNameOrEmail($firstname: String, $lastname: String, $email: String) {
  userFindByNameOrEmail(firstname: $firstname, lastname: $lastname, email: $email) {
    _id
    firstname
    lastname
    email
    roles
  }
}
    `;

/**
 * __useUserFindByNameOrEmailQuery__
 *
 * To use a Smart Query within a Vue component, call `useUserFindByNameOrEmailQuery` as the value for a query key
 * in the component's `apollo` config, passing any options required for the query.
 *
 * @param options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/core/ApolloClient/#ApolloClient.query
 *
 * @example
 * {
 *   apollo: {
 *     userFindByNameOrEmail: useUserFindByNameOrEmailQuery({
 *       variables: {
 *         firstname: // value for 'firstname'
 *         lastname: // value for 'lastname'
 *         email: // value for 'email'
 *       },
 *       loadingKey: 'loading',
 *       fetchPolicy: 'no-cache',
 *     }),
 *   }
 * }
 */
export const useUserFindByNameOrEmailQuery = createSmartQueryOptionsFunction<
  UserFindByNameOrEmailQuery,
  UserFindByNameOrEmailQueryVariables,
  ApolloError
>(UserFindByNameOrEmailDocument);
