export default {
  namespaced: true,
  state: {
    helpVersion: null,
  },
  actions: {
    readHelp({ commit }, help) {
      commit('setHelpVersion', help);
    },
  },
  mutations: {
    setHelpVersion(state, help) {
      // eslint-disable-next-line no-param-reassign
      state.helpVersion = help;
    },
  },
};
