"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getRefereeMeetingUrl = exports.shouldBeInvited = exports.DEFAULT_TEST_CANDIDATE_TALENT_ADVOCATE_EMAIL = exports.DEFAULT_CLIENT_BUSINESS_DEVELOPER_EMAIL = exports.getFullname = exports.UserTraitList = void 0;
const helpers_1 = require("@frk/helpers");
const graphql_types_1 = require("@frk/graphql-types");
const Role_core_1 = require("./Role.core");
// Mutualize user trait to sync in Rudderstack between frontend and backend
exports.UserTraitList = ['createdAt', 'email', 'fullname', 'firstname', 'lastname', 'roles'];
// TODO We should not have to check for undefined or null value : this function should just combine firstname and lastname -> the existence of user should be checked before hand -> it should always return a string
// TODO Generate the computed data about a fullname from the backend -> see User.ui functions that have the same pattern
function getFullname(user) {
    if (!user || !user.firstname || !user.lastname) {
        return null;
    }
    return [user.firstname, user.lastname.toUpperCase()].join(' ');
}
exports.getFullname = getFullname;
exports.DEFAULT_CLIENT_BUSINESS_DEVELOPER_EMAIL = 'vincent@freelancerepublik.com';
// ? Only for testing purposes
exports.DEFAULT_TEST_CANDIDATE_TALENT_ADVOCATE_EMAIL = 'clement.avocado@freelancerepublik.com';
// ! Used with a ZUser and a GraphQL User...
function shouldBeInvited(user) {
    return (((0, Role_core_1.hasRoles)(user, [graphql_types_1.Role.Freelance]) || (0, Role_core_1.hasRoles)(user, [graphql_types_1.Role.Client])) &&
        !(typeof user.auth0Id === 'string' && user.auth0Id.length) &&
        user.deleted !== true);
}
exports.shouldBeInvited = shouldBeInvited;
function getRefereeMeetingUrl(referee) {
    const normalize = (str) => (0, helpers_1.removeDiacritics)(str)
        .toLowerCase()
        .replaceAll(/[^a-z]/g, '-');
    return `https://rdv.freelancerepublik.com/meetings/${normalize(referee.firstname)}-${normalize(referee.lastname)}/meet`;
}
exports.getRefereeMeetingUrl = getRefereeMeetingUrl;
