import { minLength, required, url } from 'vuelidate/lib/validators';

import { Role } from '@frk/graphql-types';

import { GENDER_OPTIONS } from '../../../api/models/Gender.core';
import { hasRoles } from '../../../api/models/Role.core';

import { withFieldNames } from '.';
import FrkInput from '../../components/FrkInput.vue';
import FrkSelect from '../../components/FrkSelect.vue';

export const fieldDefs = {
  title: {
    label: 'Titre de votre profil',
    validations: {
      required,
      minLength: minLength(2),
    },
    component: FrkInput,
  },
  gender: {
    label: 'Genre',
    component: FrkSelect,
    props: {
      options: GENDER_OPTIONS,
    },
  },
  firstname: {
    label: 'Prénom',
    validations: {
      required,
      minLength: minLength(2),
    },
    component: FrkInput,
  },
  lastname: {
    label: 'Nom',
    validations: {
      required,
      minLength: minLength(2),
    },
    component: FrkInput,
  },
  email: {
    label: 'Adresse email',
    validations: ({ loggedUser }) => ({
      required: hasRoles(loggedUser, [Role.Admin, Role.Commercial, Role.TalentAdvocate, Role.Tech]) ? false : required,
      minLength: minLength(2),
    }),
    component: FrkInput,
    props: {
      nullIfEmpty: true,
    },
  },
  tel: {
    label: 'Téléphone',
    validations: ({ loggedUser }) => ({
      required: hasRoles(loggedUser, [Role.Admin, Role.Commercial, Role.TalentAdvocate, Role.Tech]) ? false : required,
      minLength: minLength(10),
    }),
    component: FrkInput,
  },
  linkedinUrl: {
    label: 'Profil LinkedIn',
    validations: {
      url,
    },
    component: FrkInput,
    props: {
      nullIfEmpty: true,
    },
  },
};

export const fields = withFieldNames({ fieldDefs });

export const form = {
  name: 'Profile',
  fields: [
    [fields.title, fields.gender],
    [fields.firstname, fields.lastname],
    [fields.email, fields.tel],
  ],
};
