import Router, { isAuthenticated } from '@frk/commons/ui/router';

const FrkServerError = () => import('./ui/components/FrkServerError.vue');
const ClientDetailsPage = () => import('./ui/pages/ClientDetailsPage.vue');
const ClientSearchPage = () => import('./ui/pages/ClientSearchPage.vue');
const ContractSettingsPage = () => import('./ui/pages/ContractSettingsPage.vue');
const CandidateDetailsPage = () => import('./ui/pages/CandidateDetailsPage.vue');
const CandidateSearchPage = () => import('./ui/pages/CandidateSearchPage.vue');
const InsiderDetailsPage = () => import('./ui/pages/InsiderDetailsPage.vue');
const OpportunityDetailsPage = () => import('./ui/pages/OpportunityDetailsPage.vue');
const OpportunitySearchPage = () => import('./ui/pages/OpportunitySearchPage.vue');
const RecommendationsPage = () => import('./ui/pages/RecommendationsPage.vue');
const UserProxyPage = () => import('./ui/pages/UserProxyPage.vue');

const router = new Router({
  mode: 'history',
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: '/',
      redirect: { name: 'candidateSearch' },
    },
    {
      path: '/f',
      name: 'candidateSearch',
      component: CandidateSearchPage,
      beforeEnter: isAuthenticated,
    },
    {
      path: '/c',
      name: 'clientSearch',
      component: ClientSearchPage,
      beforeEnter: isAuthenticated,
    },
    {
      path: '/i/:userId',
      name: 'insiderDetails',
      component: InsiderDetailsPage,
      beforeEnter: isAuthenticated,
      props: true,
    },
    {
      path: '/m',
      name: 'opportunitySearch',
      component: OpportunitySearchPage,
      beforeEnter: isAuthenticated,
    },
    {
      path: '/f/:userId',
      name: 'candidateDetails',
      props: true,
      component: CandidateDetailsPage,
      beforeEnter: isAuthenticated,
    },
    {
      path: '/f/:userId/recommendations',
      name: 'recommendations',
      props: true,
      component: RecommendationsPage,
      meta: {
        noSidebar: true,
      },
    },
    {
      path: '/c/:userId',
      name: 'clientDetails',
      props: true,
      component: ClientDetailsPage,
      beforeEnter: isAuthenticated,
    },
    {
      path: '/m/:missionId',
      name: 'opportunityDetails',
      props: true,
      component: OpportunityDetailsPage,
      beforeEnter: isAuthenticated,
    },
    {
      path: '/settings/contract',
      name: 'contractSettings',
      component: ContractSettingsPage,
      beforeEnter: isAuthenticated,
    },
    {
      path: '/user/:userAuth0Id',
      name: 'user',
      props: true,
      component: UserProxyPage,
      beforeEnter: isAuthenticated,
    },
    {
      path: '/users/:userAuth0Id',
      redirect: '/user/:userAuth0Id',
    },
    {
      path: '/free/:userAuth0Id',
      redirect: '/user/:userAuth0Id',
    },
    {
      path: '/client/:userAuth0Id',
      redirect: '/user/:userAuth0Id',
    },
    {
      path: '/mission/:firebaseId',
      props: true,
      component: OpportunityDetailsPage,
    },
    // {
    //   path: '/match/:matchId',
    //   name: 'match',
    //   props: true,
    //   component: MatchPage,
    // },
    {
      path: '*',
      name: 'notFound',
      component: FrkServerError,
      props: {
        code: '404',
        message: 'Page introuvable',
      },
    },
  ],
});

export default router;
