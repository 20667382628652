
import { Role } from '@frk/graphql-types';
import { hasRoles } from '@frk/commons/api/models/Role.core';
import GenericHelpParagraph from '@frk/commons/ui/components/GenericHelpParagraph.vue';

import { UserFindByNameOrEmailQuery, useUserFindByNameOrEmailQuery } from './UserFindByNameOrEmail.generated';

export default {
  name: 'UserFindByNameOrEmail',
  components: {
    GenericHelpParagraph,
  },
  props: {
    firstname: {
      type: String,
    },
    lastname: {
      type: String,
    },
    email: {
      type: String,
    },
  },
  data(): { userFindByNameOrEmail: UserFindByNameOrEmailQuery['userFindByNameOrEmail'] | null } {
    return {
      userFindByNameOrEmail: null,
    };
  },
  apollo: {
    userFindByNameOrEmail: useUserFindByNameOrEmailQuery({
      fetchPolicy: 'network-only',
      variables() {
        return {
          firstname: this.firstname,
          lastname: this.lastname,
          email: this.email,
        };
      },
      result({ data: { userFindByNameOrEmail } }) {
        if (userFindByNameOrEmail) {
          this.$emit('user-found', this.profileLink);
        }
      },
    }),
  },
  computed: {
    profileLink() {
      const userFound = this.userFindByNameOrEmail;

      const params = {
        userId: userFound._id,
      };

      if (userFound) {
        if (hasRoles(userFound, [Role.Client])) {
          return {
            name: 'clientDetails',
            params,
          };
        }

        if (hasRoles(userFound, [Role.Freelance])) {
          return {
            name: 'candidateDetails',
            params,
          };
        }
      }

      return null;
    },
    errorMessage() {
      return 'Cet utilisateur existe déjà !';
    },
  },
};
