
import Vue from 'vue';

import { isInsider } from '@frk/commons/api/models/Role.core';
import FrkVersionUpgrader from '@frk/commons/ui/components/FrkVersionUpgrader.vue';
import FrkButton from '@frk/commons/ui/components/FrkButton.vue';
import GenericLoading from '@frk/commons/ui/components/GenericLoading.vue';
import UserNotificationsContainer from '@frk/commons/ui/components/UserNotificationsContainer.vue';
import FrkDivider from '@frk/commons/ui/components/FrkDivider.vue';

import FrkServerError from './components/FrkServerError.vue';
import FrkSidebar from './components/FrkSidebar.vue';
import FrkSidebarLayout from './layouts/FrkSidebarLayout.vue';

export default Vue.extend({
  components: {
    FrkServerError,
    FrkSidebar,
    FrkSidebarLayout,
    FrkVersionUpgrader,
    FrkButton,
    GenericLoading,
    UserNotificationsContainer,
    FrkDivider,
  },
  metaInfo: {
    titleTemplate: titleChunk => (titleChunk ? `${titleChunk} - Admin FreelanceRepublik` : 'Admin FreelanceRepublik'),
  },
  computed: {
    isInsider() {
      return isInsider(this.$auth.loggedUser);
    },
  },
});
