<template>
  <div class="client-notice-period">
    <FrkInput
      class="value"
      :disabled="disabled"
      :min="0"
      :name="`${name}--value`"
      :step="1"
      :value="val"
      @input="value => onChanged({ value, unit })"
      null-if-empty
      type="number"
    />
    <div class="gutter"></div>
    <FrkSelect
      class="unit"
      :disabled="disabled"
      :name="`${name}--unit`"
      :options="unitOptions"
      :value="unit"
      @input="unit => onChanged({ value: val, unit })"
    />
  </div>
</template>

<script>
import { ClientNoticePeriodUnitEnum } from '@frk/graphql-types';
import { NOTICE_PERIOD_UNIT_OPTIONS } from '../../api/models/Client.core';

import FrkInput from './FrkInput.vue';
import FrkSelect from './FrkSelect.vue';

export default {
  name: 'ClientNoticePeriod',
  components: {
    FrkInput,
    FrkSelect,
  },
  props: {
    disabled: {
      type: Boolean,
      optional: true,
      default: false,
    },
    name: {
      type: String,
      optional: true,
      default: 'ClientNoticePeriod',
    },
    value: {
      type: Object, // NoticePeriodSchema
      optional: true,
    },
  },
  computed: {
    val() {
      return this.value ? this.value.value : null;
    },
    unit() {
      return this.value ? this.value.unit : null;
    },
    unitOptions() {
      return NOTICE_PERIOD_UNIT_OPTIONS.map(o => ({ ...o, label: o.label({ value: this.val || 0 }) }));
    },
  },
  methods: {
    onChanged({ value, unit }) {
      // If 'unit' or 'value' has been unset => unset the form
      if ((value === null && this.val !== null) || (unit === null && this.unit !== null)) {
        return this.$emit('input', null);
      }

      // If 'unit' or 'value' has been set => set the form
      if ((value !== null && this.val === null) || (unit !== null && this.unit === null)) {
        return this.$emit('input', {
          value: value !== null ? value : 1,
          unit: ClientNoticePeriodUnitEnum.Days,
        });
      }

      return this.$emit('input', { value, unit });
    },
  },
};
</script>

<style lang="scss" scoped>
.client-notice-period {
  display: flex;

  .value {
    flex: 1;
  }
  .gutter {
    width: $space-medium;
  }
  .unit {
    flex: 3;
  }
}
</style>
