<template>
  <textarea
    class="tw-frk-input"
    :cols="cols"
    :class="{ 'has-error': hasError }"
    :data-test="$attrs['data-test'] || name"
    :readonly="disabled"
    :name="name"
    :placeholder="placeholder"
    :rows="rows"
    :value="value"
    @blur="$emit('blur')"
    @input="handleInput"
    ref="textarea"
    data-form-type="other"
  ></textarea>
</template>

<script>
function setHeightToFitContent() {
  this.style.height = 'auto';
  this.style.height = `${this.scrollHeight}px`;
}

export default {
  name: 'FrkTextArea',
  props: {
    type: {
      type: String,
      required: false,
      default: 'text',
    },
    placeholder: {
      type: String,
      required: false,
      default: '',
    },
    value: {
      required: false,
    },
    rows: {
      required: false,
      default: 5,
    },
    cols: {
      required: false,
      default: 40,
    },
    name: {
      type: String,
    },
    autoHeight: {
      type: Boolean,
      default: false,
    },
    hasError: {
      type: Boolean,
      default: false,
    },
    noLineBreak: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    if (this.autoHeight) {
      // Init the height to fit with the content
      setHeightToFitContent.call(this.$refs.textarea);

      // Ajust the height when the content is updated
      this.$refs.textarea.addEventListener('input', setHeightToFitContent);

      // Remove the listener to avoid memory leaks.
      // Code style ref: https://fr.vuejs.org/v2/guide/components-edge-cases.html#Ecouteurs-d%E2%80%99evenements-programmatiques
      this.$once('hook:beforeDestroy', function () {
        this.$refs.textarea.removeEventListener('input', setHeightToFitContent);
      });
    }
  },
  methods: {
    handleInput(event) {
      const text = this.noLineBreak ? event.target.value.replace(/\r?\n|\r/g, ' ') : event.target.value;

      this.$emit('input', text);
    },
  },
};
</script>
