import { getStringSize } from '@frk/helpers';
import { helpers as validatorHelpers } from 'vuelidate/lib/validators';

import dateUtils from './date';

const { ref, req, withParams } = validatorHelpers;

export function dateAfter(date) {
  return withParams({ type: 'dateAfter', date }, value => (value ? dateUtils.isDateAfter(value, date) : true));
}

export function dateAfterField(fieldName, _vm) {
  return withParams({ type: 'dateAfterField', fieldName, _vm }, function (value, vm) {
    return value ? dateUtils.isDateAfter(value, ref(fieldName, this, vm)) : true;
  });
}

export function dateBefore(date) {
  return withParams({ type: 'dateBefore', date }, value => (value ? dateUtils.isDateBefore(value, date) : true));
}

/**
 * A vuelidate validator implementing field label on vuelidate schema.
 *
 * @param {string} fieldLabel
 */
export const label = fieldLabel => withParams({ type: fieldLabel }, () => true);

export const maxSize = max => withParams({ type: 'maxSize', max }, value => !req(value) || getStringSize(value) <= max);
