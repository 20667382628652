import algoliasearch from 'algoliasearch';

import { setupAlgoliaIndexesFor } from '@frk/commons/api/utils/algolia/algolia-indexes';

// TODO Find a better architecture -> remove Algolia, lol
import { GetAlgoliaSettingsViaOverEngineeredArchitectureDocument } from '../components/FrkAis.generated';

export default {
  install: (Vue, { apolloClient }) => {
    let freelancesIndex;
    let clientsIndex;
    let missionsIndex;
    let isReady = false;

    const init = async () => {
      const {
        data: { settings },
      } = await apolloClient.query({
        query: GetAlgoliaSettingsViaOverEngineeredArchitectureDocument,
      });

      const { algoliaSearchId, algoliaSearchKey, testId } = settings;

      if (!(algoliaSearchId && algoliaSearchKey)) {
        isReady = false;
      } else {
        const { VUE_APP_CONFIG_ENV } = process.env;
        const { getIndexName } = setupAlgoliaIndexesFor(VUE_APP_CONFIG_ENV, testId);

        const client = algoliasearch(algoliaSearchId, algoliaSearchKey);

        freelancesIndex = client.initIndex(getIndexName('freelances'));
        clientsIndex = client.initIndex(getIndexName('clients'));
        missionsIndex = client.initIndex(getIndexName('missions'));
        isReady = true;
      }
    };

    const isAlgoliaReady = () => isReady;

    function setupAlgolia(fn) {
      return async (...args) => {
        if (!isAlgoliaReady()) {
          await init();
        }

        return fn(...args);
      };
    }

    const getIndices = setupAlgolia(() => ({
      freelances: freelancesIndex,
      clients: clientsIndex,
      missions: missionsIndex,
    }));

    const setupSearchBusinessTagsByIndex = setupAlgolia(
      index =>
        async function (query) {
          const indices = await getIndices();
          const response = await indices[index].searchForFacetValues('businessTags', query);

          return response.facetHits.map(hit => hit.value);
        }
    );

    const algolia = {
      init,
      isAlgoliaReady,
      getIndices,
      setupSearchBusinessTagsByIndex,
    };

    // eslint-disable-next-line no-param-reassign
    Vue.prototype.$algolia = algolia;
  },
};
