"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
exports.default = (0, vue_1.defineComponent)({
    __name: 'FrkDivider',
    props: {
        text: {
            type: String,
            default: '',
        },
        small: {
            type: Boolean,
            default: false,
        },
    },
    setup(__props) {
        return { __sfc: true, };
    }
});
