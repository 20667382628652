<template>
  <div
    class="generic-help-paragraph"
    :class="`level-${colorLevel}`"
  >
    <i :class="iconClass"></i><span> <slot></slot></span>
  </div>
</template>

<script>
export default {
  name: 'GenericHelpParagraph',
  props: {
    colorLevel: {
      type: String,
      optional: true,
      validator(value) {
        return ['danger', 'info', 'valid', 'warning'].includes(value);
      },
      default: 'info',
    },
    iconClass: {
      type: Array,
      optional: true,
      default: () => ['fas', 'fa-info-circle'],
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@frk/commons/ui/assets/styles/_vars.scss';

.generic-help-paragraph {
  font-family: $font-family-special;
  border-radius: $border-radius-medium;
  border-style: dashed;
  border-width: 1px;
  padding: $space-small $space-medium;
  display: flex;
  align-items: center;

  @each $level, $color in $color-levels {
    &.level-#{$level} {
      border-color: $color;
      color: $color;

      a {
        color: $color;
      }
    }
  }
}

i {
  margin-right: $space-medium;
  font-size: $font-size-xlarge;
}

span {
  font-size: $font-size-small;
}
</style>
