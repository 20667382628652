
/* eslint-disable camelcase */
import Vue, { PropType } from 'vue';
import VueGoogleAutocomplete from 'vue-google-autocomplete';
import { Address } from '@frk/graphql-types';

import { getRegionFromGeocoding } from '../../api/models/Address.core';

// Manually defined from source code :
// see https://github.com/olefirenko/vue-google-autocomplete/blob/edda11089e8724ab5b9d4337728d435f6bae1c2a/src/VueGoogleAutocomplete.vue#L19-L28
// and https://github.com/olefirenko/vue-google-autocomplete/blob/edda11089e8724ab5b9d4337728d435f6bae1c2a/src/VueGoogleAutocomplete.vue#L334-L348
export interface FormattedResult {
  subpremise: google.maps.GeocoderAddressComponent['short_name'];
  street_number: google.maps.GeocoderAddressComponent['short_name'];
  route: google.maps.GeocoderAddressComponent['long_name'];
  locality: google.maps.GeocoderAddressComponent['long_name'];
  administrative_area_level_1: google.maps.GeocoderAddressComponent['short_name'];
  administrative_area_level_2: google.maps.GeocoderAddressComponent['long_name'];
  country: google.maps.GeocoderAddressComponent['long_name'];
  postal_code: google.maps.GeocoderAddressComponent['short_name'];
  latitude: number;
  longitude: number;
}

export default Vue.extend({
  name: 'FrkPlacesInput',
  components: { VueGoogleAutocomplete },
  props: {
    required: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: "Saisissez l'adresse puis sélectionnez la suggestion appropriée",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
    },
    types: {
      type: String,
      // address granularity support: https://github.com/freelanceRepublik/westeros/pull/897#pullrequestreview-982287958
      // docs: https://developers.google.com/maps/documentation/places/web-service/supported_types#table3
      default: 'geocode',
    },
    value: {
      type: Object as PropType<Address | null>,
    },
  },
  data(): {
    address: Address | null;
    addressValue: google.maps.places.PlaceResult['formatted_address'];
    uniqueId: string;
  } {
    const randomId = Math.random().toString();

    return {
      address: this.value,
      addressValue: this.value?.value,
      uniqueId: randomId,
    };
  },
  watch: {
    address(newAddress) {
      this.$emit('input', newAddress);
    },
    value(newValue) {
      this.address = newValue;
      this.addressValue = newValue?.value || '';
    },
  },
  mounted() {
    if (this.addressValue) {
      // @ts-ignore
      this.$refs.autocomplete.autocompleteText = this.addressValue;
    }
  },
  methods: {
    handleInputChange({ newVal }: { newVal: string }) {
      if (newVal === '') {
        this.addressValue = newVal;
        this.address = null;
      }
    },
    handlePlaceChanged(address: FormattedResult, place: google.maps.places.PlaceResult) {
      // Complete human-readable value provided by Google
      this.addressValue = place.formatted_address;
      if (typeof this.addressValue === 'string') {
        // `vue-google-autocomplete` formatted administrative area level 1 is computed to `short_name`
        // In our business, we prefer `long_name` region : Île-de-France instead of IDF
        // So we compute it ourselves
        const region = getRegionFromGeocoding(place);

        // API computable value
        this.address = {
          region,
          city: address.locality || null,
          country: address.country,
          latlng: { lat: address.latitude, lng: address.longitude },
          placeId: place.place_id,
          value: this.addressValue,
        };
      } else {
        this.address = null;
      }
    },
  },
});
