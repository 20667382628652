/* eslint-disable */

import * as SchemaTypes from '@frk/graphql-types';

import { gql } from '@frk/graphql-vue-apollo-patch';
import { createMutationFunction, createSmartQueryOptionsFunction, createSmartSubscriptionOptionsFunction } from 'vue-apollo-smart-ops';
import { ApolloError } from '@apollo/client';
export type ResumeDataFragment = { __typename?: 'Freelance', resume?: { __typename?: 'Resume', url: string, updatedAt: any, parsedAt?: any | null } | null };

export type CarreerDataFragment = { __typename?: 'Freelance', experiences: Array<{ __typename?: 'Experience', id: string, actual: boolean, description?: string | null, startDate?: any | null, endDate?: any | null, job: string, location?: string | null, name: string }>, educations: Array<{ __typename?: 'Education', id: string, description?: string | null, institution: string, name: string, year?: number | null }> };

export type FreelanceMergeCareersMutationVariables = SchemaTypes.Exact<{
  career: SchemaTypes.CareerInput;
  userId: SchemaTypes.Scalars['ID']['input'];
}>;


export type FreelanceMergeCareersMutation = { __typename?: 'Mutation', freelanceMergeCareers?: { __typename?: 'User', _id?: string | null, freelance?: { __typename?: 'Freelance', experiences: Array<{ __typename?: 'Experience', id: string, actual: boolean, description?: string | null, startDate?: any | null, endDate?: any | null, job: string, location?: string | null, name: string }>, educations: Array<{ __typename?: 'Education', id: string, description?: string | null, institution: string, name: string, year?: number | null }> } | null } | null };

export type FreelanceResumeAddMutationVariables = SchemaTypes.Exact<{
  fileUrl: SchemaTypes.Scalars['String']['input'];
  userId?: SchemaTypes.InputMaybe<SchemaTypes.Scalars['ID']['input']>;
}>;


export type FreelanceResumeAddMutation = { __typename?: 'Mutation', freelanceResumeAdd?: { __typename?: 'User', _id?: string | null, freelance?: { __typename?: 'Freelance', experiences: Array<{ __typename?: 'Experience', id: string, actual: boolean, description?: string | null, startDate?: any | null, endDate?: any | null, job: string, location?: string | null, name: string }>, educations: Array<{ __typename?: 'Education', id: string, description?: string | null, institution: string, name: string, year?: number | null }>, resume?: { __typename?: 'Resume', url: string, updatedAt: any, parsedAt?: any | null } | null } | null } | null };

export type ProspectFromResumeAddMutationVariables = SchemaTypes.Exact<{
  input: SchemaTypes.ProspectFromResumeAddInput;
}>;


export type ProspectFromResumeAddMutation = { __typename?: 'Mutation', prospectFromResumeAdd?: { __typename?: 'User', _id?: string | null, freelance?: { __typename?: 'Freelance', experiences: Array<{ __typename?: 'Experience', id: string, actual: boolean, description?: string | null, startDate?: any | null, endDate?: any | null, job: string, location?: string | null, name: string }>, educations: Array<{ __typename?: 'Education', id: string, description?: string | null, institution: string, name: string, year?: number | null }>, resume?: { __typename?: 'Resume', url: string, updatedAt: any, parsedAt?: any | null } | null } | null } | null };

export const ResumeDataFragmentDoc = gql`
    fragment ResumeData on Freelance {
  resume {
    url
    updatedAt
    parsedAt
  }
}
    `;
export const CarreerDataFragmentDoc = gql`
    fragment CarreerData on Freelance {
  experiences {
    id
    actual
    description
    startDate
    endDate
    job
    location
    name
  }
  educations {
    id
    description
    institution
    name
    year
  }
}
    `;
export const FreelanceMergeCareersDocument = gql`
    mutation freelanceMergeCareers($career: CareerInput!, $userId: ID!) {
  freelanceMergeCareers(career: $career, userId: $userId) {
    _id
    freelance {
      ...CarreerData
    }
  }
}
    ${CarreerDataFragmentDoc}`;

/**
 * __freelanceMergeCareersMutation__
 *
 * To run a mutation, you call `freelanceMergeCareersMutation` within a Vue component and pass it
 * your Vue app instance along with any options that fit your needs.
 *
 * @param app, a reference to your Vue app instance (which must have a `$apollo` property)
 * @param options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/core/ApolloClient/#ApolloClient.mutate
 * @param client (optional), which can be an instance of `DollarApollo` or the `mutate()` function provided by an `<ApolloMutation>` component
 *
 * @example
 * const { success, data, errors } = freelanceMergeCareersMutation(this, {
 *   variables: {
 *     career: // value for 'career'
 *     userId: // value for 'userId'
 *   },
 * });
 */
export const freelanceMergeCareersMutation = createMutationFunction<
  FreelanceMergeCareersMutation,
  FreelanceMergeCareersMutationVariables,
  ApolloError
>(FreelanceMergeCareersDocument);

export const FreelanceResumeAddDocument = gql`
    mutation freelanceResumeAdd($fileUrl: String!, $userId: ID) {
  freelanceResumeAdd(fileUrl: $fileUrl, userId: $userId) {
    _id
    freelance {
      ...CarreerData
      ...ResumeData
    }
  }
}
    ${CarreerDataFragmentDoc}
${ResumeDataFragmentDoc}`;

/**
 * __freelanceResumeAddMutation__
 *
 * To run a mutation, you call `freelanceResumeAddMutation` within a Vue component and pass it
 * your Vue app instance along with any options that fit your needs.
 *
 * @param app, a reference to your Vue app instance (which must have a `$apollo` property)
 * @param options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/core/ApolloClient/#ApolloClient.mutate
 * @param client (optional), which can be an instance of `DollarApollo` or the `mutate()` function provided by an `<ApolloMutation>` component
 *
 * @example
 * const { success, data, errors } = freelanceResumeAddMutation(this, {
 *   variables: {
 *     fileUrl: // value for 'fileUrl'
 *     userId: // value for 'userId'
 *   },
 * });
 */
export const freelanceResumeAddMutation = createMutationFunction<
  FreelanceResumeAddMutation,
  FreelanceResumeAddMutationVariables,
  ApolloError
>(FreelanceResumeAddDocument);

export const ProspectFromResumeAddDocument = gql`
    mutation prospectFromResumeAdd($input: ProspectFromResumeAddInput!) {
  prospectFromResumeAdd(input: $input) {
    _id
    freelance {
      ...CarreerData
      ...ResumeData
    }
  }
}
    ${CarreerDataFragmentDoc}
${ResumeDataFragmentDoc}`;

/**
 * __prospectFromResumeAddMutation__
 *
 * To run a mutation, you call `prospectFromResumeAddMutation` within a Vue component and pass it
 * your Vue app instance along with any options that fit your needs.
 *
 * @param app, a reference to your Vue app instance (which must have a `$apollo` property)
 * @param options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/core/ApolloClient/#ApolloClient.mutate
 * @param client (optional), which can be an instance of `DollarApollo` or the `mutate()` function provided by an `<ApolloMutation>` component
 *
 * @example
 * const { success, data, errors } = prospectFromResumeAddMutation(this, {
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export const prospectFromResumeAddMutation = createMutationFunction<
  ProspectFromResumeAddMutation,
  ProspectFromResumeAddMutationVariables,
  ApolloError
>(ProspectFromResumeAddDocument);
