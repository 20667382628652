"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isInsider = exports.hasRoles = exports.isInsiderRole = exports.isAbstractRole = exports.isPhysicalRole = void 0;
const graphql_types_1 = require("@frk/graphql-types");
function isPhysicalRole(role) {
    return role !== graphql_types_1.Role.Logged && role !== graphql_types_1.Role.Unlogged && role !== graphql_types_1.Role.All;
}
exports.isPhysicalRole = isPhysicalRole;
function isAbstractRole(role) {
    return !isPhysicalRole(role);
}
exports.isAbstractRole = isAbstractRole;
function isInsiderRole(role) {
    return isPhysicalRole(role) && role !== graphql_types_1.Role.Client && role !== graphql_types_1.Role.Freelance && role !== graphql_types_1.Role.Und;
}
exports.isInsiderRole = isInsiderRole;
// ! Passing the user object causes typing conflicts
// TODO Change first param to be user roles directly and not the user object
function hasRoles(user, roles) {
    if (!user || !user.roles) {
        return false;
    }
    return user.roles.length && roles.length ? roles.some(role => user.roles.includes(role)) : false;
}
exports.hasRoles = hasRoles;
// ? A user is considered insider if they have at least one insider role
function isInsider(user) {
    if (!user || !user.roles) {
        return false;
    }
    return user.roles.some(isInsiderRole);
}
exports.isInsider = isInsider;
