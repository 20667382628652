"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const vue_2 = require("vue");
exports.default = (0, vue_1.defineComponent)({
    __name: 'FrkTag',
    props: {
        label: null,
        color: { default: 'stone' },
        removable: { type: Boolean, default: false },
        clickable: { type: Boolean, default: false }
    },
    emits: ["click", "remove"],
    setup(__props, { emit }) {
        const props = __props;
        const colorClasses = (0, vue_2.computed)(() => {
            const classes = [];
            switch (props.color) {
                case 'black':
                    classes.push(`tw-bg-stone-900`, `tw-text-white`);
                    break;
                case 'primary':
                    classes.push(`tw-bg-primary`, `tw-text-white`);
                    break;
                case 'stone':
                    classes.push(`tw-bg-${props.color}-100`, `tw-text-${props.color}-600`);
                    break;
                case 'white':
                    classes.push(`tw-bg-white`, `tw-border-gray-200`, `tw-border`, `tw-text-gray-800`);
                    break;
                default:
                    classes.push(`tw-bg-${props.color}-100`, `tw-text-${props.color}-500`, `dark:tw-bg-${props.color}-800/30`, `dark:tw-text-${props.color}-400`);
            }
            return classes;
        });
        const clickableClasses = (0, vue_2.computed)(() => {
            if (props.clickable) {
                return 'tw-cursor-pointer';
            }
            return '';
        });
        return { __sfc: true, props, emit, colorClasses, clickableClasses };
    }
});
