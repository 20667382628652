"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SELECTION_KEY_SKILLS_LEVEL_OPTIONS = exports.FREELANCE_KEYSKILL_DESCRIPTION_MAX_SIZE = exports.justNumber = exports.SKILL_MIN_LENGTH = exports.SKILL_MAX_LENGTH = exports.SKILL_ALLOWED_CHARACTERS_REGEX = void 0;
const graphql_types_1 = require("@frk/graphql-types");
exports.SKILL_ALLOWED_CHARACTERS_REGEX = /([^A-Za-z0-9À-ÿ.\-#+ ']|( \+ ?)|( \()|(-\())|( - ?)|-$/;
exports.SKILL_MAX_LENGTH = 30;
exports.SKILL_MIN_LENGTH = 1;
function justNumber(value) {
    return /^\d+$/.test(value);
}
exports.justNumber = justNumber;
// Althought this constant is based on the same-named CustomerIO constant, both constants should remain independent:
// - This local constant has a business purpose: TAC and Freelance should not write a book description
// - The CustomerIO constant (same name) has a technical purpose: don't reach the attribute size limit (1000 bytes).
exports.FREELANCE_KEYSKILL_DESCRIPTION_MAX_SIZE = 500;
exports.SELECTION_KEY_SKILLS_LEVEL_OPTIONS = [
    { value: graphql_types_1.SelectionKeySkillsLevel.Level_0, label: 'Aucune experience' },
    { value: graphql_types_1.SelectionKeySkillsLevel.Level_1, label: 'Débutant' },
    { value: graphql_types_1.SelectionKeySkillsLevel.Level_2, label: 'Confirmé' },
    { value: graphql_types_1.SelectionKeySkillsLevel.Level_3, label: 'Senior' },
];
